<script setup>
import {computed} from 'vue';
import DOMPurify from 'dompurify';
// import {marked} from 'marked';
// import { mangle } from "marked-mangle";
// import hljs from 'https://cdn.staticfile.org/highlight.js/11.7.0/es/highlight.min.js';
import isInCode from "../utils/mdInCode"; // 用于判断是否显示光标
// import {markedHighlight} from "marked-highlight";
// import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css'	//样式
import hljs from 'highlight.js' // https://highlightjs.org
import MarkdownIt from 'markdown-it';
const md = MarkdownIt({
    // Enable HTML tags in source
    html:true,

    // Use '/' to close single tags (<br />).
    // This is only for full CommonMark compatibility.
    xhtmlOut:true,

    // Convert '\n' in paragraphs into <br>
    breaks:true,

    // CSS language prefix for fenced blocks. Can be
    // useful for external highlighters.
    langPrefix:'language-',

    // Autoconvert URL-like text to links
    linkify:true,

    // Enable some language-neutral replacement + quotes beautification
    // For the full list of replacements, see https://github.com/markdown-it/markdown-it/blob/master/lib/rules_core/replacements.mjs
    typographer:true,

    // Double + single quotes replacement pairs, when typographer enabled,
    // and smartquotes on. Could be either a String or an Array.
    //
    // For example, you can use '«»„“' for Russian, '„“‚‘' for German,
    // and ['«\xA0', '\xA0»', '‹\xA0', '\xA0›'] for French (including nbsp).
    quotes: '“”‘’',

    // Highlighter function. Should return escaped HTML,
    // or '' if the source string is not changed and should be escaped externally.
    // If result starts with <pre... internal wrapper is skipped.
    highlight: function (str, lang) {
      if (lang && hljs.getLanguage(lang)) {
        try {
          return '<pre><code class="hljs">' +
                hljs.highlight(str, { language: lang, ignoreIllegals: true }).value +
                '</code></pre>';
        } catch (__) {}
      }

      return '<pre><code class="hljs">' + md.utils.escapeHtml(str) + '</code></pre>';
    }
})
const props = defineProps({
  // 输入的 markdown 文本
  text: {
    type: String,
    default: ""
  },
  // 是否需要显示光标？比如在消息流结束后是不需要显示光标的
  showCursor: {
    type: Boolean,
    default: true
  }
})
// marked.use(mangle());
// marked.use(markedHighlight({
//   langPrefix: 'hljs language-',
//   highlight(code, lang) {
//     const language = hljs.getLanguage(lang) ? lang : 'plaintext';
//     return hljs.highlight(code, { language }).value;
//   }
// }));

// 配置高亮
// marked.setOptions({
//   langPrefix: 'hljs language-',
//   headerIds: false,
//   mangle:false,
//   highlight: function (code, lang) {
//     try {
//       if (lang) {
//         const language = hljs.getLanguage(lang) ? lang : 'plaintext';
//         return hljs.highlight(code, {language: language}).value
//       } else {
//         return hljs.highlightAuto(code).value
//       }
//     } catch (error) {
//       return code
//     }
//   },
//   gfmtrue: true,
//   breaks: true
// })

// 计算最终要显示的 html 文本
const html = computed(() => {
  // 将 markdown 转为 html
  function trans(text) {
    return DOMPurify.sanitize(md.render(text));
  }
  // 光标元素，可以用 css 美化成你想要的样子
  const cursor = '<span class="cursor"></span>';
  if (props.showCursor) {
    // 判断 AI 正在回的消息是否有未闭合的代码块。
    const inCode = isInCode(props.text)
    if (inCode) {
      // 有未闭合的代码块，不显示光标
      return trans(props.text);
    } else {
      // 没有未闭合的代码块，将光标元素追加到最后。
      return trans(props.text + cursor);
    }
  } else {
    // 父组件明确不显示光标
    return trans(props.text);
  }
})

</script>

<template>
  <div v-html="html" class="markdown">
  </div>
</template>

<style lang="css">
/** 设置代码块样式 **/
.markdown{
  color: #333;
  font-size: 14px;
}
.markdown table
  {
      border-collapse: collapse;
      margin: 0 auto;
      text-align: center;
      font-size: 14px;
      font-weight: normal;
  }
  .markdown table td,.markdown table th
  {
      border: 1px solid #cad9ea;
      color: #666;
      height: 30px;
  }
  .markdown table thead th
  {
      background-color: #10a27e;
      color: #fff;
      font-weight: bolder;
      min-width: 100px;
  }
  .markdown table tr:nth-child(odd)
  {
      background: #fff;
  }
  .markdown table tr:nth-child(even)
  {
      background: #F5FAFA;
  }
.markdown pre {
  /* @apply bg-[#282c34] p-4 mt-4 rounded-md text-white w-full overflow-x-auto; */
}
.markdown code {
  width: 100%;
}

/** 控制段落间的上下边距 **/
.markdown p {
    line-height: 2;
}
.markdown p:first-child {
  /* margin-top: 0; */
}

/** 小代码块样式，对应 markdown 的 `code` **/
.markdown :not(pre) > code {
  /* @apply bg-[#282c34] px-1 py-[2px] text-[#e06c75] rounded-md; */
}

/** 列表样式 **/
.markdown ol {
  list-style-type: decimal;
  padding-left: 40px;
}
.markdown ul {
  list-style-type: disc;
  padding-left: 40px;
}

/** 光标样式 **/
.markdown .cursor {
  display: inline-block;
  width: 2px;
  height: 20px;
  /* @apply bg-gray-800 dark:bg-gray-100; */
  animation: blink 1.2s step-end infinite;
  margin-left: 2px;
  vertical-align: sub;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

</style>
