<template>
    <div id="chat">
        <div v-if="isMobile" class="chatBox">
            <div class="chatBox-top">
                <div class="chatBox-top-imgBox" @click="toPersonal">
                    <img style="width: 2rem;height: 2rem;border: solid #f4f4f4 0.5px;border-radius: 50%;"
                        src="@/assets/chatgpt.png" />
                </div>
                <div class="chatBox-top-text">
                    <span style="margin-right:20px;">
                        {{ nickName }}
                    </span>
                    <span
                        style="background: linear-gradient(95.9deg,#ffeec3 3.14%,#ffe5ba 23.99%,#ffd8aa 43.77%,#ffd4a2 62.06%,#ffc896 79.86%,#fcb4ba 97.91%);border-radius:1rem;padding: 0.4rem 1rem;">
                        <label style="color:#6b2d00;font-size:12px;fongt-weight:700;">个人用户</label>
                    </span>
                </div>
                <div style="width: 2rem;padding-right: 10px;">
                    <span style="color:#fff;font-size:14px;" @click="logout">退出</span>
                </div>
            </div>
            <div ref="main" class="chatBox-middle">
                <div ref="chatInfo" class="chatInfo" id="chatInfo" @scroll="handleScroll">
                    <div class="gpt-message-box" v-for="(item, index) in chatList" :key="index"
                        :class="[!item.isGPT ? 'my-message-box' : 'gpt-message-box']">
                        <div class="user-box-img">
                            <img v-if="item.isGPT"
                                style="width: 2.5rem;height: 2.5rem;border-radius:50%;border: 1px solid #f4f4fe;"
                                src="@/assets/chatgpt.png" />
                            <img v-else
                                style="width: 2.5rem;height: 2.5rem;border-radius:50%;border: 1px solid #f4f4fe;"
                                src="@/assets/rabbit.jpg" />
                        </div>
                        <div class="chat-message">
                            <div class="chat-message-name"
                                :class="[!item.isGPT ? 'chat-message-name1' : 'chat-message-name']">
                                <span>{{ item.name }}</span><span class="nowDate">{{ item.time }}</span>
                            </div>
                            <div class="chat-message-text"
                                :class="[!item.isGPT ? 'chat-message-text1' : 'chat-message-text']">
                                <!-- <span v-html="item.message"></span> -->
                                <!-- <span v-if="item.isCode">
                                    <pre><code v-html="item.message"></code></pre>
                                </span> -->
                                <span>
                                    {{ item.message }}
                                </span>
                                <!-- <v-md-preview :text="item.message"></v-md-preview> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chatBox-bottom">
                <!-- <van-divider dashed>GPT</van-divider> -->
                <div class="chatBox-textarea">
                    <van-cell-group inset>
                        <!-- <van-field
                        v-model="inputText"
                        rows="4"
                        autosize
                        required
                        type="textarea"
                        maxlength="50"
                        placeholder="请输入你的问题"
                        show-word-limit
                    /> -->
                        <van-field v-model="inputText" rows="4" center required type="textarea" clearable
                            show-word-limit placeholder="请输入你的问题">
                            <template #button>
                                <van-button size="small" type="primary" @click="sendMessage">发送</van-button>
                            </template>
                        </van-field>
                    </van-cell-group>
                </div>
                <!-- <div class="chatBox-sendOut">
                    <van-button class="sendOut" type="primary" @click="askQuestion">发送</van-button>
                </div> -->
            </div>
        </div>
        <el-container v-else class="pc">
            <el-header class="header">
                <div class="chatBox-top">
                    <div class="chatBox-top-imgBox" @click="toPersonal">
                        <img style="width: 2rem;height: 2rem;border: solid #f4f4f4 0.5px;border-radius: 50%;"
                            src="@/assets/chatgpt.png" />
                         <label style="margin-left: 10px;color:#FFF;font-size:18px;fongt-weight:600;">GPT-4</label>
                    </div>
                    <div class="chatBox-top-text">
                    </div>
                    <div style="padding-right: 10px;display: flex;justify-content: center;align-items: center;">
                        <span v-if="userInfo.Company"
                            style="cursor:pointer;margin-right:10px;background: linear-gradient(95.9deg,#ffeec3 3.14%,#ffe5ba 23.99%,#ffd8aa 43.77%,#ffd4a2 62.06%,#ffc896 79.86%,#fcb4ba 97.91%);border-radius:1rem;padding: 0.4rem 1rem;">
                            <label
                                style="cursor:pointer;color:#6b2d00;font-size:12px;font-weight:700;">{{userInfo.Company.Name}}</label>
                        </span>
                        <span v-else @click="entepriseVisible=true"
                            style="cursor:pointer;margin-right:10px;background: linear-gradient(95.9deg,#ffeec3 3.14%,#ffe5ba 23.99%,#ffd8aa 43.77%,#ffd4a2 62.06%,#ffc896 79.86%,#fcb4ba 97.91%);border-radius:1rem;padding: 0.4rem 1rem;">
                            <label style="cursor:pointer;color:#6b2d00;font-size:12px;font-weight:700;">申请企业用户</label>
                        </span>
                        <span v-if="userInfo.Role==2" @click="entepriseManagementDialogVisible=true"
                            style="cursor:pointer;margin-right:10px;background:#10a27e;border-radius:1rem;padding: 0.4rem 1rem;">
                            <label style="cursor:pointer;color:#fff;font-size:12px;font-weight:700;">管理企业</label>
                        </span>

                        <span
                            style="display: flex; justify-content: center;align-items: center; color:#fff;font-size:14px;font-weight: 600;margin-right:10px;min-width: 50px;">
                            <p style="color:rgb(252, 180, 186)" v-if="userInfo.Role==1">管理员:</p>
                            <p style="color:rgb(252, 180, 186)" v-if="userInfo.Role==2">超级管理员:</p>
                            <p>{{ userInfo.Name??'新用户' }} </p>
                            <!-- <p style="color:rgb(252, 180, 186)"><a href="https://ai.beltoo.com" target="_blank">前往贝兔官网</a></p> -->
                        </span>
                        <el-icon size="20px" style="cursor:pointer;color:#fff;margin-right:10px;"
                            @click="settingVisible=true">
                            <Setting />
                        </el-icon>
                        <!-- <span style="margin-right:20px;background: linear-gradient(95.9deg,#ffeec3 3.14%,#ffe5ba 23.99%,#ffd8aa 43.77%,#ffd4a2 62.06%,#ffc896 79.86%,#fcb4ba 97.91%);border-radius:1rem;padding: 0.4rem 1rem;">
                            <label style="color:#6b2d00;font-size:12px;font-weight:700;">个人用户</label>
                        </span> -->
                        <span style="color:#fff;font-size:14px;" @click="logout">退出</span>
                        <span style="cursor:pointer;margin-left:10px;margin-right:10px;background:#b0bbf7;border-radius:1rem;padding: 0.4rem 1rem;">
                            <label style="cursor:pointer;color:#fff;font-size:12px;font-weight:700;"><a href="https://ai.beltoo.com" target="_blank">前往贝兔官网</a></label>
                        </span>
                    </div>
                </div>
            </el-header>
            <el-container style="height: calc(100% - 100px);">
                <el-aside class="side">
                    <div class="tools">
                        <el-button type="primary" @click="addSessionDialogVisible=true">新建话题</el-button>
                    </div>
                    <div class="session-list" v-loading="sessionLoading" element-loading-text="loading">
                        <div :key="idx" @click="onSessionClick(session)" v-for="(session, idx) in sessionList"
                            :class="{ 'session-item': true, 'session-active': session.ID == currentSession.ID }">
                            <span class="session-name">{{ session.Name }}</span>
                            <el-popconfirm @confirm="removeSession(session)" title="确定删除吗?" confirm-button-text="是的"
                                cancel-button-text="取消">
                                <template #reference>
                                    <el-icon size="12" color="#aaa" style="margin-left:8px;">
                                        <Delete />
                                    </el-icon>
                                </template>
                            </el-popconfirm>
                        </div>
                    </div>
                    <!-- <div class="footer">
                        <el-icon size="20px" style="cursor:pointer;" @click="showSetting()"><Setting/></el-icon>
                    </div> -->
                </el-aside>
                <el-main class="main" v-loading="chatListLoading" :element-loading-text="loadingText">
                    <div class="chatBox-middle" ref="main">
                        <div class="chatInfo" ref="chatInfo" id="chatInfo" @scroll="handleScroll">
                            <div class="gpt-message-box" v-for="(item, index) in chatList" :key="index"
                                :class="[!item.isGPT ? 'my-message-box' : 'gpt-message-box']">
                                <div class="user-box-img">
                                    <img v-if="item.isGPT"
                                        style="width: 2.5rem;height: 2.5rem;border-radius:50%;border: 1px solid #f4f4fe;"
                                        src="@/assets/chatgpt.png" />
                                    <img v-else
                                        style="width: 2.5rem;height: 2.5rem;border-radius:50%;border: 1px solid #f4f4fe;"
                                        src="@/assets/rabbit.jpg" />
                                </div>
                                <div class="chat-message">
                                    <div class="chat-message-name"
                                        :class="[!item.isGPT ? 'chat-message-name1' : 'chat-message-name']">
                                        <span>{{ item.name }}</span><span class="nowDate">{{ item.time }}</span>
                                    </div>
                                    <div class="chat-message-text"
                                        :class="[!item.isGPT ? 'chat-message-text1' : 'chat-message-text']">

                                        <!-- <span v-html="item.message"></span> -->
                                        <!-- <span v-if="item.isCode">
                                        <pre><code v-html="item.message"></code></pre>
                                    </span> -->
                                        <div>
                                            <!-- {{ item.message }} -->
                                            <img v-if="item.image_url" style="width:auto;height:300px;margin-top:20px;" :src="item.image_url"/>
                                            <GPTRender :text="item.message"></GPTRender>
                                            <audio autoplay v-if="item.isGPT&&item.audio" controls style="width: 100%;">
                                                <source :src="item.audio" type="audio/mpeg">
                                                Your browser does not support this audio format.
                                            </audio>
                                        </div>
                                        <div v-if="item.isGPT" style="position:absolute;right:-60px;bottom:6px;cursor: pointer;padding: 0 5px;" >
                                            <el-icon @click="copyMessage(item.message);" style="width:20px;cursor: pointer;margin-right: 8px;" size="14" color="#1989fa">
                                                <CopyDocument />
                                            </el-icon>
                                            <img src="@/assets/icon/speech.png" style="width:16px;cursor: pointer;" @click="tts(item)"/>
                                        </div>
                                        <!-- <v-md-preview :text="item.message"></v-md-preview> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chatBox-bottom">
                        <div v-if="currentChatMessage" style="position: absolute;top: -1.5rem;z-index: 99;width: 100%;text-align: center;">
                            <el-button type="warning"  size="small" @click="stopMessage">终止回答</el-button>
                        </div>
                        <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
                            <button @click="selectFile" style="position: absolute;width: 42px;height: 42px;z-index: 99;border: none;left: 15px;background: #fff;cursor: pointer;" aria-disabled="false"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M9 7a5 5 0 0 1 10 0v8a7 7 0 1 1-14 0V9a1 1 0 0 1 2 0v6a5 5 0 0 0 10 0V7a3 3 0 1 0-6 0v8a1 1 0 1 0 2 0V9a1 1 0 1 1 2 0v6a3 3 0 1 1-6 0z" clip-rule="evenodd"></path></svg></button>
                            <input ref="chatFile" type="file" style="display:none;" @change="handleFileChange" />
                            <!-- <el-input
                            v-model="inputText"
                            :rows="4"
                            type="textarea"
                            style="padding-left:20px;margin-right:1rem;border-radius: 26px;"
                            placeholder="请输入你的问题"
                            /> -->
                            <div class="chatBox-message-input" >
                                <img v-if="selectedImage" class="chatBox-message-input-img" :src="selectedImage" alt="Selected Image" />
                                <span v-if="selectedImage" class="chatBox-message-input-img-del" @click="deleteImage">×</span>
                                <textarea class="chatBox-message-input-text"  v-model="inputText" autocomplete="off" 
                                @keydown.enter.exact.prevent="sendMessage" @paste="clipPaste" 
                                placeholder="请输入你的问题(Shift+Enter换行)" id="el-id-9124-7" style="min-height: 33px;"></textarea>
                            </div>
                            <el-button type="primary" @click="sendMessage"> <el-icon size="16" color="#fff" style="">
                                 <Position />
                            </el-icon></el-button>
                        </div>
                    </div>
                </el-main>
            </el-container>
            <el-dialog v-model="addSessionDialogVisible" title="新建话题" width="400px" align-center>
                <el-alert title="每个单独的话题会自动记录上下文信息" type="success" style="margin-bottom: 20px;" />
                <el-input v-model="newSessionInput" placeholder="请输入话题名称" clearable />
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="addSessionDialogVisible = false">取消</el-button>
                        <el-button type="primary" @click="saveSession();">
                            确定
                        </el-button>
                    </span>
                </template>
            </el-dialog>
            <el-dialog v-model="gptSettingVisible" title="设置GPT" width="400px" align-center>
                <el-alert title="更改服务地址或者Key可能会导致进行中的话题中断" type="success" style="margin-bottom: 20px;" />
                <el-alert title="Priority越大,会优先使用" type="success" style="margin-bottom: 20px;" />
                服务地址：
                <el-input v-model="gptSetting.ServerUrl" placeholder="服务地址" clearable style="margin-bottom: 20px;" >
                </el-input>
                Key：
                <el-input v-model="gptSetting.Key" placeholder="Key" clearable style="margin-bottom: 20px;"/>
                Key：
                <el-input v-model="gptSetting.Model" placeholder="Model eg:gpt-4" clearable style="margin-bottom: 20px;"/>
                优先级
                <el-input v-model="gptSetting.Priority" type="number" placeholder="Priority" clearable />
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="gptSettingVisible = false">取消</el-button>
                        <el-button type="primary" @click="saveGPTSetting();">
                            确定
                        </el-button>
                    </span>
                </template>
            </el-dialog>
            <el-dialog v-model="entepriseVisible" title="企业用户申请" width="500px" align-center>
                <el-alert title="申请通过后您的账号将升级为企业账号的管理员" type="success" style="margin-bottom: 20px;" />
                <el-form :model="form" label-width="120px">
                    <el-form-item label="企业名称">
                        <el-input v-model="companyName" placeholder="请输入企业名称" clearable />
                    </el-form-item>
                    <el-form-item label="营业执照号">
                        <el-input v-model="companyLicense" placeholder="请输入营业执照号" clearable />
                    </el-form-item>
                </el-form>
                <!-- <el-button type="primary" @click="saveSession();">
                            确定
                </el-button> -->
                <template #footer>
                    <span class="dialog-footer">
                        <!-- <el-button @click="entepriseVisible = false">取消</el-button> -->
                        <el-button type="primary" @click="submitCompanyApply();">
                            确定
                        </el-button>
                    </span>
                </template>
            </el-dialog>
            <el-dialog v-model="addCompanyUserDialogVisible" title="添加企业用户" width="300px">
                <el-input v-model="addUserPhone" placeholder="请输入手机号" clearable />
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="addCompanyUserDialogVisible = false">取消</el-button>
                        <el-button type="primary" @click="setUserCompany();">
                            确定
                        </el-button>
                    </span>
                </template>
            </el-dialog>
            <el-dialog v-model="entepriseManagementDialogVisible" title="管理企业" width="800px" align-center>
                <el-table :data="companyList" :key="ID" style="height: 300px;width: 100%;">
                    <el-table-column prop="Name" label="企业信息">
                        <template #default="scope">
                            <p>{{scope.row.Name }}</p>
                            <p>{{scope.row.License }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="WordCount" label="剩余字数" width="100" />
                    <el-table-column prop="CreateTime" label="申请时间" width="160" />
                    <el-table-column label="操作" width="180" align="center">
                        <template #default="scope">
                            <div style="display: flex;flex-direction: column;align-items: center;">
                                <el-popconfirm v-if="scope.row.Status!=2" @confirm="approveCompany(scope.row.ID)"
                                    title="确定通过吗?" confirm-button-text="是的" cancel-button-text="取消">
                                    <template #reference>
                                        <el-button type="success" size="small">通过审核</el-button>
                                    </template>
                                </el-popconfirm>
                                <el-button v-if="scope.row.Status==2" style="margin-top: 6px;margin-left: 0;"  type="primary"
                                    @click="rechargeWordCountDialogVisible=true;rechargeCompanyID=scope.row.ID;" size="small">充值字数
                                </el-button>
                                <el-button v-if="scope.row.Status==2" style="margin-top: 6px;margin-left: 0;" type="primary"
                                    @click="companyUserDialogVisible=true;rechargeCompanyID=scope.row.ID;companyUsers=[];loadCompanyUsers();" size="small">管理用户
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    v-model:current-page="companyFilter.Pager.PageNumber"
                    v-model:page-size="companyFilter.Pager.PageSize"
                    :page-sizes="[5,10,50,100]"
                    :small="true"
                    :default-page-size="10"
                    :disabled="false"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="companyFilter.Pager.TotalCount"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
                <el-dialog v-model="rechargeWordCountDialogVisible" width="300px" title="充值字数" align-center append-to-body>
                    <div style="display: flex;justify-content: center;align-items: center;">
                        <label style="width: 60px;font-weight: 500;font-size: 14px;color:#666;">字数:</label>
                        <!-- <el-input type="number" v-model="rechargeWordCount" placeholder="请输入要充值的字数" clearable /> -->
                        <el-input-number style="width: 100%;" v-model="rechargeWordCount" placeholder="请输入要充值的字数" :min="100" step="100" :max="1000000"/>
                    </div>
                    <div style="display: flex;justify-content: center;align-items: center;margin-top:10px;">
                        <label style="width: 60px;font-weight: 500;font-size: 14px;color:#666;">备注:</label>
                        <el-input v-model="rechargeSummary" placeholder="请输入备注" clearable />
                    </div>
                    <template #footer>
                        <span class="dialog-footer">
                            <el-button @click="rechargeWordCountDialogVisible = false;rechargeCompanyID=0;">取消</el-button>
                            <el-button type="primary" @click="sumbitRechargeWordCount();">
                                确定
                            </el-button>
                        </span>
                    </template>
                </el-dialog>
                <el-dialog v-model="companyUserDialogVisible" width="700px" title="企业用户" align-center append-to-body>
                    <el-button type="primary" size="small" style="width: 60px;margin-left: 20px;"
                            @click="addCompanyUserDialogVisible=true">添加用户</el-button>
                    <el-table :data="companyUsers" :key="ID" style="height: 300px;width: 100%;">
                        <el-table-column prop="Name" label="用户" />
                            <el-table-column prop="Phone" label="手机号" width="120" />
                            <el-table-column label="操作" width="80">
                                <template #default="scope">
                                    <el-popconfirm v-if="userInfo.ID!=scope.row.ID" style="flex:0.5;"
                                        @confirm="removeUserCompany(scope.row.ID)" title="确定删除吗?"
                                        confirm-button-text="是的" cancel-button-text="取消">
                                        <template #reference>
                                            <el-icon size="14" color="#aaa" style="cursor: pointer;">
                                                <Delete />
                                            </el-icon>
                                        </template>
                                    </el-popconfirm>
                                </template>
                            </el-table-column>
                </el-table>
                <el-pagination
                    v-model:current-page="companyUserFilter.Pager.PageNumber"
                    v-model:page-size="companyUserFilter.Pager.PageSize"
                    :page-sizes="[5,10,50,100]"
                    :small="true"
                    :default-page-size="10"
                    :disabled="false"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="companyUserFilter.Pager.TotalCount"
                    @size-change="handleUserSizeChange"
                    @current-change="handleUserCurrentChange"
                />
                    <template #footer>
                        <!-- <span class="dialog-footer">
                            <el-button @click="companyUserDialogVisible = false;">取消</el-button>
                            <el-button type="primary" @click="sumbitRechargeWordCount();">
                                确定
                            </el-button>
                        </span> -->
                    </template>
                </el-dialog>
            </el-dialog>
            <el-drawer v-model="settingVisible" title="设置" size="600px" direction="ltr">
                <div style="padding:0 20px 20px 20px;display: flex;flex-direction: column;">
                    <div style="color: #333;font-weight: 700;font-size: 16;">个人信息</div>
                    <div
                        style="display: flex;flex-direction: column;justify-content: center;align-items: center;padding: 30px;margin-bottom: 50px;">
                        <div
                            style="width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;margin-bottom: 20px;">
                            <label style="width: 120px;font-weight: 500;font-size: 14px;color:#666;">我的称呼:</label>
                            <el-input v-model="userName" placeholder="请输入称呼" clearable style="flex: 1;" />
                            <el-button type="primary" size="small" style="width: 60px;margin-left: 20px;"
                                @click="saveUserName">保存</el-button>
                        </div>
                        <div v-if="userInfo.Company"
                            style="width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;margin-bottom: 20px;">
                            <label style="width: 120px;font-weight: 500;font-size: 14px;color:#666;">我的企业:</label>
                            <label style="flex:1;font-weight: 500;font-size: 14px;">{{ userInfo.Company.Name }}</label>
                        </div>
                        <div v-if="userInfo.Company"
                            style="width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;margin-bottom: 20px;">
                            <label style="width: 120px;font-weight: 500;font-size: 14px;color:#666;">企业账户剩余字数:</label>
                            <label
                                style="flex:1;font-weight: 500;font-size: 14px;">{{ userInfo.Company.WordCount<0?0:userInfo.Company.WordCount }}</label>
                        </div>
                        <div
                            style="width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;">
                            <label style="width: 120px;font-weight: 500;font-size: 14px;color:#666;">个人账户剩余字数:</label>
                            <label
                                style="flex:1;font-weight: 500;font-size: 14px;">{{ userInfo.WordCount<0?0:userInfo.WordCount }}</label>
                        </div>
                    </div>
                    <div v-if="userInfo.Role>0" style="color: #333;font-weight: 700;font-size: 16;">企业用户(最多10个用户)
                        <el-button type="primary" size="small" style="width: 60px;margin-left: 20px;"
                            @click="addCompanyUserDialogVisible=true">添加用户</el-button>
                    </div>
                    <div v-if="userInfo.Role>0"
                        style="display: flex;flex-direction: column;padding: 30px;margin-bottom: 30px;">
                        <el-table :data="myCompanyUsers" :key="ID" style="width: 80%;height:200px;">
                            <el-table-column prop="Name" label="用户" />
                            <el-table-column prop="Phone" label="手机号" width="120" />
                            <el-table-column label="操作" width="80">
                                <template #default="scope">
                                    <el-popconfirm v-if="userInfo.ID!=scope.row.ID" style="flex:0.5;"
                                        @confirm="removeUserCompany(scope.row.ID)" title="确定删除吗?"
                                        confirm-button-text="是的" cancel-button-text="取消">
                                        <template #reference>
                                            <el-icon size="14" color="#aaa" style="cursor: pointer;">
                                                <Delete />
                                            </el-icon>
                                        </template>
                                    </el-popconfirm>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div v-if="userInfo.Role==2" style="color: #333;font-weight: 700;font-size: 16;">
                        GPT设置
                        <el-button type="primary" size="small" style="width: 60px;margin-left: 20px;"
                            @click="gptSettingVisible=true;gptSetting={Key:'',ServerUrl:'',Priority:1}">添加Key</el-button>
                    </div>
                    <div v-if="userInfo.Role==2"
                        style="display: flex;flex-direction: column;justify-content: center;align-items: center;padding: 30px;">
                        <el-table :data="gptSettings" :key="ID" style="width: 100%;height:200px;">
                            <el-table-column prop="ServerUrl" label="服务地址" />
                            <el-table-column prop="Key" label="Key"/>
                            <el-table-column prop="Model" label="Model"/>
                            <el-table-column prop="Priority" label="Priority"/>
                            <el-table-column label="操作" width="80">
                                <template #default="scope">
                                    <el-icon @click="gptSettingVisible=true;gptSetting=scope.row" size="14" color="#1989fa" style="margin-right:8px;cursor: pointer;">
                                        <Edit />
                                    </el-icon>
                                    <el-popconfirm
                                        @confirm="deleteGptSetting(scope.row.ID)" title="确定删除吗?"
                                        confirm-button-text="是的" cancel-button-text="取消">
                                        <template #reference>
                                            <el-icon size="14" color="#aaa" style="cursor: pointer;">
                                                <Delete />
                                            </el-icon>
                                        </template>
                                    </el-popconfirm>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- <div style="width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;margin-bottom: 20px;">
                            <label style="width: 120px;font-weight: 500;font-size: 14px;color:#666;">服务地址:</label>
                            <label
                                style="flex:1;font-weight: 500;font-size: 14px;">{{ gptSetting.ServerUrl }}</label>
                        </div>
                        <div style="width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;">
                            <label style="width: 120px;font-weight: 500;font-size: 14px;color:#666;">GPT4Key:</label>
                            <label style="flex:1;font-weight: 500;font-size: 14px;overflow-x:auto;">{{ gptSetting.Key }}</label>
                        </div> -->
                    </div>
                </div>
            </el-drawer>
        </el-container>
    </div>
</template>
<script>
    import {
        mapState,
        mapAcAtions,
        mapMutations
    } from 'vuex'
    import {
        Field,
        Button,
        Toast,
        Divider,
        Image,
        showConfirmDialog,
        showNotify
    } from 'vant'
    import {
        ElMessage,
        ElMessageBox
    } from 'element-plus'
    import moment from "moment"
    import config from '../config'
    import GPTService from '../services/GPTService'
    import GPTRender from '../components/GPTRender.vue'
    export default {
        name: 'chat',
        components: {
            [Field.name]: Field,
            [Button.name]: Button,
            [Divider.name]: Divider,
            [Image.name]: Image,
            [Toast.name]: Toast,
            GPTRender,
        },
        computed: {
            ...mapState({
                isLogin: state => state.user.isLogin,
                phone: state => state.user.phone,
                token: state => state.user.token,
                photo: state => state.user.photo,
                nickName: state => state.user.nickName,
            }),
        },
        data() {
            return {
                inputText: "",
                selectedImage:null,
                chatlistFilter:{Pager:{PageSize:100,PageNumber:1},CreateTime:{Sort:-2}},
                newSessionInput: "",
                isMobile: false,
                sessionList: [],
                sessionLoading: false,
                chatListLoading: false,
                loadingText:'加载对话记录',
                currentSession: {
                    ID: '',
                    Name: '默认聊天'
                },
                chatList: [],
                companyUsers: [],
                myCompanyUsers:[],
                companyList: [],
                currentMsgId: 0,
                addSessionDialogVisible: false,
                currentChatMessage: null,
                cancelRequest:false,//用于中止请求
                settingVisible: false,
                entepriseVisible: false,
                addCompanyUserDialogVisible: false,
                entepriseManagementDialogVisible: false,
                rechargeWordCountDialogVisible: false,
                companyUserDialogVisible:false,
                companyName: '',
                companyLicense: '',
                userName: '',
                addUserPhone: '',
                userInfo: {
                    Company: null
                },
                rechargeCompanyID: 0,
                rechargeWordCount: 0,
                rechargeSummary: '',
                companyFilter:{
                    CreateTime:{Sort:-2},
                    Pager:{
                        PageNumber:1,
                        PageSize:5,
                        TotalCount:100
                    },
                },
                companyUserFilter:{
                    CreateTime:{Sort:-2},
                    Pager:{
                        PageNumber:1,
                        PageSize:20,
                        TotalCount:100
                    },
                },
                gptSetting:{
                   ServerUrl:'',
                   Key:''
                },
                gptSettings:[{
                   ServerUrl:'',
                   Key:''
                }],
                gptSettingVisible:false,
            }
        },
        methods: {
            handleSizeChange(number){
                this.companyFilter.Pager.PageSize=number;
                this.loadCompanys();
            },
            handleCurrentChange(number){
                this.companyFilter.Pager.PageNumber=number;
                this.loadCompanys();
            },
            handleUserSizeChange(number){
                this.companyUserFilter.Pager.PageSize=number;
                this.loadCompanyUsers();
            },
            handleUserCurrentChange(number){
                this.companyUserFilter.Pager.PageNumber=number;
                this.loadCompanyUsers();
            },
            showMessage(msg){
                if (this.isMobile) {
                    showNotify({
                        type: 'warning',
                        message:msg
                    });
                } else {
                    ElMessage({
                        message:msg,
                        type: 'warning',
                    })
                }
            },
            saveSession() {
                if (this.newSessionInput) {
                    let _session_id = this.guid2();
                    var session = {
                        ID: _session_id,
                        Name: this.newSessionInput
                    };
                    this.sessionLoading = true;
                    GPTService.SaveSession(session).then(s => {
                        if (s.Success) {
                            this.newSessionInput = "";
                            this.sessionList.splice(0,0,session);
                            if (this.sessionList.length == 1) {
                                this.chatList=[];
                                this.chatlistFilter.Pager.PageNumber=1;
                                this.currentSession = session;
                                // this.connectChatServer(this.currentSession.ID);
                                this.loadChatList(this.currentSession.ID);
                            }
                            this.addSessionDialogVisible = false;
                        }
                        this.sessionLoading = false;
                    }).catch(() => {
                        this.sessionLoading = false;
                    });
                }
            },
            removeSession(session) {
                var idx = this.sessionList.findIndex(s => s.ID == session.ID);
                if (idx > -1) {
                    this.sessionLoading = true;
                    GPTService.RemoveSession(this.sessionList[idx].ID).then(s => {
                        if (s.Success) {
                            this.sessionList.splice(idx, 1);
                        }
                        this.sessionLoading = false;
                    });
                }
            },
            onSessionClick(session) {
                this.chatList=[];
                this.chatlistFilter.Pager.PageNumber=1;
                this.currentSession = session;
                // this.connectChatServer(this.currentSession.ID);
                this.loadChatList(this.currentSession.ID);
            },
            getIsMobile() {
                let userAgentInfo = navigator.userAgent;
                let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
                let getArr = Agents.filter(i => userAgentInfo.includes(i));
                return getArr.length ? true : false;
            },
            toPersonal() {
                // alert(1);
                // this.$router.push({name:'personal'});
            },
            appendMessage(msg) {
                if (msg) {
                    msg.time = moment.utc(msg.time).local().format('YYYY-MM-DD HH:mm:ss')
                    this.chatList.push(msg);
                    this.scrollToBottom();
                }
            },
            // askQuestion() {
            //     // this.sendMessage();
            //     // return;
            //     if (!this.inputText) {
            //         // Toast.showToast('请输入')
            //         this.showMessage('请输入问题');
            //         return
            //     }
            //     if (!this.currentSession) {
            //         this.showMessage('无效的话题');
            //         return
            //     }
            //     var _data = {
            //         "prompt": this.inputText,
            //         "session_id": this.currentSession.ID,
            //     };
            //     let myQuestion = {
            //         id: 2,
            //         name: this.nickName,
            //         time: new Date(),
            //         isGPT: false,
            //         images: '',
            //         message: this.inputText
            //     }
            //     this.appendMessage(myQuestion);
            //     this.inputText = ''
            //     if (this.currentChatMessage) {
            //         this.currentChatMessage = null;
            //     }
            //     GPTService.Chat(_data).then(json => {
            //         if (json.Success) {
            //             //思考中...
            //         } else {
            //             this.showMessage(json.Message);
            //             return
            //         }
            //     })
            // },
            // stopQuestion(){
            //     GPTService.StopChat(this.currentSession.ID).then(json => {
            //         if (json.Success) {
            //             //思考中...
            //         } else {
            //             this.showMessage(json.Message);
            //             return
            //         }
            //     })
            // },
            scrollToBottom(isPager) {
                this.$nextTick(() => {
                    if(this.$refs.main){
                        let _top=this.$refs.chatInfo.scrollHeight;
                        if(isPager){
                            if(this.preScrollTo&&this.preScrollTo>0){
                                _top=this.$refs.chatInfo.scrollHeight-this.preScrollTo;
                            }
                        }
                        else{
                            _top=this.$refs.chatInfo.scrollHeight;
                            this.preScrollTo=null;
                        }
                        console.log(_top);
                        console.log(this.preScrollTo);
                        this.$refs.chatInfo.scrollTo && this.$refs.chatInfo.scrollTo({
                            top:_top,
                            behavior: 'smooth'
                        });
                        this.preScrollTo=this.$refs.chatInfo.scrollHeight;
                    }
                })
            },
            formatDate() { //当前时间 2021-4-23 18:00:24 星期五
                let date = new Date();
                let year = date.getFullYear(); // 年
                let month = date.getMonth() + 1; // 月
                let day = date.getDate(); // 日
                let week = date.getDay(); // 星期
                let weekArr = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
                let hour = date.getHours(); // 时
                hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
                let minute = date.getMinutes(); // 分
                minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
                let second = date.getSeconds(); // 秒
                second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
                this.nowDate = `${year}-${month}-${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
            },
            logout: function () {
                if(this.isMobile){
                    showConfirmDialog({
                        title: '',
                        message: '确定退出账号吗？',
                    })
                    .then(() => {
                        // fetch(this.global.apiHost + "/api/auth/logout", {
                        //         method: 'GET',
                        //         mode: 'cors',
                        //         credentials: 'include'
                        //     })
                        //     .then(response => response.json())
                        //     .then(data => {
                        //         if (data.Success) {
                                    this.$store.dispatch('user/logout');
                                    this.$router.push({
                                        name: 'login'
                                    });
                            //     } else {
                            //         console.log(1);
                            //     }
                            // }).catch(e => {
                            //     console.log(e);
                            // });
                    })
                    .catch(() => {
                        // on cancel
                    });
                }
                else{
                    ElMessageBox.confirm('确定退出账号吗？',"退出登录",{cancelButtonText:'取消',confirmButtonText:'退出'})
                    .then(() => {
                        // fetch(this.global.apiHost + "/api/auth/logout", {
                        //                         method: 'GET',
                        //                         mode: 'cors',
                        //                         credentials: 'include'
                        //                     })
                        //                     .then(response => response.json())
                        //                     .then(data => {
                        //                         if (data.Success) {
                                                    this.$store.dispatch('user/logout');
                                                    this.$router.push({
                                                        name: 'login'
                                                    });
                                            //     } else {
                                            //         console.log(1);
                                            //     }
                                            // }).catch(e => {
                                            //     console.log(e);
                                            // });
                    })
                    .catch(() => {
                    // catch error
                    })
                }
            },
            //设置cookie
            setCookie: function (cname, cvalue, exdays) {
                var d = new Date();
                d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
                var expires = "expires=" + d.toUTCString();
                console.info(cname + "=" + cvalue + "; " + expires);
                document.cookie = cname + "=" + cvalue + "; " + expires;
                console.info(document.cookie);
            },
            //获取cookie
            getCookie: function (cname) {
                var name = cname + "=";
                var ca = document.cookie.split(';');
                console.log("获取cookie,现在循环")
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    console.log(c)
                    while (c.charAt(0) == ' ') c = c.substring(1);
                    if (c.indexOf(name) != -1) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            //清除cookie
            clearCookie: function (cname) {
                this.setCookie(cname, "", -1);
            },
            guid2() {
                function S4() {
                    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
                }
                return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
            },
            loadSessionList(callback) {
                // this.sessionLoading=true;
                //获取话题列表
                GPTService.SessionList().then(s => {
                    if (s.Success) {
                        this.sessionList = s.Data;
                        callback && callback(s.Data);
                    }
                    // this.sessionLoading=false;
                }).catch(e => {
                    this.sessionLoading = false;
                });
            },
            loadMyCompanyUsers(companyID) {
                GPTService.GetCompanyUsers(companyID).then(s => {
                    if (s.Success) {
                        this.myCompanyUsers = s.Data;
                    }
                    // this.sessionLoading=false;
                }).catch(e => {});
            },
            loadCompanyUsers() {
                this.companyUserFilter.CompanyID={Value:this.rechargeCompanyID};
                GPTService.GetCompanyUserPageList(this.companyUserFilter).then(s => {
                    if (s.Success) {
                        this.companyUsers = s.Data.Results;
                        this.companyUserFilter.Pager.TotalCount=s.Data.TotalCount;
                    }
                    // this.sessionLoading=false;
                }).catch(e => {});
            },
            loadCompanys() {
                GPTService.CompanyPageList(this.companyFilter).then(s => {
                    if (s.Success) {
                        this.companyList = s.Data.Results;
                        this.companyFilter.Pager.TotalCount=s.Data.TotalCount;
                    }
                    // this.sessionLoading=false;
                }).catch(e => {});
            },
            handleScroll(event) {
                const div = event.target;
                // console.log(div.scrollTop);
                if (div.scrollTop === 0) {
                    // div滚动到了顶部
                    this.chatlistFilter.Pager.PageNumber++;
                    this.loadChatList(this.currentSession.ID);
                }
            },
            loadChatList(sessionid) {
                //获取话题列表
                this.chatListLoading = true;
                this.loadingText='加载...';
                this.chatlistFilter.SessionID={Value:sessionid};
                // console.log('之前',this.$refs.chatInfo.scrollHeight)
                // this.scrollTo=this.$refs.chatInfo.scrollHeight;
                GPTService.ChatPageList(this.chatlistFilter).then(s => {
                    if (s.Success) {
                        s.Data.Results.map((item,i) => {
                            // alert(JSON.stringify(item));
                            let row= {
                                id: item.ID,
                                name: item.IsGPT == false ? this.userInfo?.Name : "GPT",
                                time: item.CreateTime,
                                isGPT: item.IsGPT,
                                image_url:item.image_url,
                                message: item.Message
                            }
                            this.chatList.splice(0,0,row);
                        });
                        // console.log('之后',this.$refs.chatInfo.scrollHeight)
                        // if(this.chatlistFilter.Pager.PageNumber==1){
                        //     this.scrollToBottom();
                        // }
                        // else{
                        // }
                        this.scrollToBottom(true);
                    }
                    // setTimeout(() => {
                    this.chatListLoading = false;
                    // }, 500);
                }).catch(e => {
                    this.chatListLoading = false;
                });
            },
            connectChatServer(sessionId) {
                var that = this;
                if (this.sse) {
                    this.sse.close();
                    this.sse = null;
                }
                this.sse = new EventSource(config.apiHost + '/api/gpt/stream1?sessionId=' + sessionId);
                this.sse.error=function(e){
                    this.showMessage('话题超时...');
                }
                this.sse.open=function(e){
                    this.showMessage('对话服务连接成功');
                }
                this.sse.onmessage = function (event) {
                    if (event.data == '[DONE]') {
                        that.currentChatMessage = null;
                        return;
                    }
                    if (that.currentChatMessage) {
                        that.currentChatMessage.message += event.data;
                        that.scrollToBottom();
                    }
                    else {
                        let customerData = {
                            id: 4,
                            name: 'GPT',
                            isGPT: true,
                            time: new Date(),
                            message: event.data,
                        }
                        that.appendMessage(customerData);
                        that.currentChatMessage = customerData;
                    }
                };
            },
            submitCompanyApply() {
                var _data = {
                    Name: this.companyName,
                    License: this.companyLicense
                };
                if (!_data.Name) {
                    this.showMessage("请输入企业名称");
                    return false;
                }
                if (!_data.License) {
                    this.showMessage("请输入企业执照");
                    return false;
                }
                GPTService.ApplyCompany(_data).then(s => {
                    if (s.Success) {
                        this.entepriseVisible = false;
                        this.showMessage("申请成功,请等待审核结果。");
                    } else {
                        this.showMessage(s.Message);
                    }
                }).catch(e => {});
            },
            approveCompany(companyID) {
                var _data = {
                    CompanyID: companyID,
                    Status: 2
                };
                GPTService.ApproveCompany(_data).then(s => {
                    if (s.Success) {
                        this.entepriseManagementDialogVisible = false;
                        this.showMessage("审核成功。");
                        this.loadCompanys();
                    } else {
                        this.showMessage(s.Message);
                    }
                }).catch(e => {});
            },
            setUserCompany() {
                var userPhone = this.addUserPhone;
                if (!userPhone) {
                    this.showMessage("请输入用户手机号");
                    return false;
                }
                GPTService.SetUserCompany(userPhone, this.userInfo.CompanyID).then(s => {
                    if (s.Success) {
                        this.addCompanyUserDialogVisible = false;
                        this.showMessage("添加成功");
                        this.loadMyCompanyUsers(this.userInfo.CompanyID);
                    } else {
                        this.showMessage(s.Message);
                    }
                }).catch(e => {});
            },
            removeUserCompany(userID) {
                GPTService.RemoveUserCompany(userID).then(s => {
                    if (s.Success) {
                        this.showMessage("移除成功");
                        this.loadMyCompanyUsers(this.userInfo.CompanyID);
                    } else {
                        this.showMessage(s.Message);
                    }
                }).catch(e => {});
            },
            loadUserInfo() {
                GPTService.UserInfo().then(s => {
                    if (s.Success) {
                        this.userInfo = s.Data;
                        this.userName = s.Data.Name;
                        if (this.userInfo.CompanyID > 0) {
                            this.loadMyCompanyUsers(this.userInfo.CompanyID);
                        }
                        if (this.userInfo.Role == 2) {
                            this.loadCompanys();
                            this.getSetting();
                        }
                    } else {
                        this.showMessage(s.Message);
                    }
                }).catch(e => {});
            },
            saveUserName() {
                var _data = {
                    Name: this.userName
                };
                if (!_data.Name) {
                    this.showMessage('请输入用户名称');
                    return false;
                }
                GPTService.SaveUserInfo(_data).then(s => {
                    if (s.Success) {
                        this.userInfo.Name = _data.Name;
                        this.showMessage('修改成功');
                    } else {
                        this.showMessage(s.Message);
                    }
                }).catch(e => {});
            },
            sumbitRechargeWordCount() {
                var _data = {
                    CompanyID: this.rechargeCompanyID,
                    WordCount: this.rechargeWordCount,
                    Summary: this.rechargeSummary
                };
                if (_data.CompanyID == 0) {
                    ElMessage({
                        message: "无效的企业ID",
                        type: 'warning',
                    });
                    return false;
                }
                if (_data.WordCount <= 0) {
                    ElMessage({
                        message: "请输入有效的字数",
                        type: 'warning',
                    });
                    return false;
                }
                if (!_data.Summary) {
                    ElMessage({
                        message: "请输入备注",
                        type: 'warning',
                    });
                    return false;
                }
                GPTService.RechargeCompanyWordCount(_data).then(s => {
                    if (s.Success) {
                        ElMessage({
                            message: "充值成功",
                            type: 'success',
                        });
                        this.rechargeWordCountDialogVisible = false;
                        //    this.loadCompanys();
                        this.loadUserInfo();
                    } else {
                        ElMessage({
                            message: s.Message,
                            type: 'warning',
                        })
                    }
                }).catch(e => {});
            },
            saveGPTSetting(){
                debugger
                var _data = this.gptSetting;
                if (!_data.ServerUrl) {
                    this.showMessage('服务地址不能为空');
                    return false;
                }
                if (!_data.Key) {
                    this.showMessage('GPT4Key不能为空');
                    return false;
                }
                GPTService.SaveGPTSetting(_data).then(s => {
                    if (s.Success) {
                        this.showMessage('设置成功');
                        this.gptSettingVisible = false;
                        this.getSetting();
                    } else {
                        this.showMessage(s.Message);
                    }
                }).catch(e => {});
            },
            getSetting(){
                GPTService.GetGPTSetting().then(s => {
                    if (s.Success) {
                        this.gptSettings=s.Data;
                        this.gptSetting =s.Data.length>0?this.gptSettings[0]:null;

                    } else {
                        this.showMessage(s.Message);
                    }
                }).catch(e => {});
            },
            deleteGptSetting(keyID) {
                GPTService.DeleteGPTSetting(keyID).then(s => {
                    if (s.Success) {
                        this.showMessage("移除成功");
                        this.getSetting(); //this.loadMyCompanyUsers(this.userInfo.CompanyID);
                    } else {
                        this.showMessage(s.Message);
                    }
                }).catch(e => {});
            },
            sendMessage(){
                var that=this;
                if (!this.inputText) {
                    // Toast.showToast('请输入')
                    this.showMessage('请输入问题');
                    return
                }
                if (!this.currentSession) {
                    this.showMessage('无效的话题');
                    return
                }
                var _data = {
                    "prompt": this.inputText,
                    "session_id": this.currentSession.ID,
                    "image_url":this.selectedImage
                };
                let myQuestion = {
                    id: 2,
                    name: this.nickName,
                    time: new Date(),
                    isGPT: false,
                    image_url:this.selectedImage,
                    message: this.inputText
                }
                this.appendMessage(myQuestion);
                // this.inputText = ''
                if (this.currentChatMessage) {
                    this.currentChatMessage = null;
                }
                //添加思考中状态...
                let customerData = {
                    id: 4,
                    name: 'GPT',
                    isGPT: true,
                    time: new Date(),
                    message: '正在思考中...',
                    // image_url:that.selectedImage
                }
                that.appendMessage(customerData);
                that.currentChatMessage = customerData;
                that.selectedImage=null;
                that.inputText = ''
                this.httpRequest = new XMLHttpRequest();
                this.httpRequest.onreadystatechange = function () {
                    // console.log(this.httpRequest.readyState);
                    // if (this.cancelRequest==true) {

                    //     this.cancelRequest = false;
                    // }
                    // if (that.httpRequest.status === 200) {
                    //     // 处理请求成功的逻辑
                    //     that.selectedImage=null;
                    //     that.inputText = ''
                    // }
                };
                this.httpRequest.onprogress = function (progressEvent) {
                    //处理响应数据
                    // div.textContent = `GPT：${progressEvent.target.responseText}`;
                    console.log(progressEvent.target.responseText);
                    let data=progressEvent.target.responseText;
                    // if (data == '--end--') {
                    //     that.currentChatMessage = null;
                    //     return;
                    // }
                    if (that.currentChatMessage) {
                        that.currentChatMessage.message= data;
                        that.scrollToBottom();
                    }
                    else {
                        let customerData = {
                            id: 4,
                            name: 'GPT',
                            isGPT: true,
                            time: new Date(),
                            message: data,
                        }
                        that.appendMessage(customerData);
                        that.currentChatMessage = customerData;
                    }
                };
                //请求是否成功都会执行
                this.httpRequest.onloadend = function (progressEvent) {
                    //恢复按钮状态
                    that.currentChatMessage = null;
                    // sendButton.disabled = false;
                };
                //打开请求，设置请求方法和地址，并设置异步为true
                this.httpRequest.open("POST", this.global.apiHost +"/api/gpt/ChatNew", true);
                let authString = localStorage.getItem('chatgpt-web-client');
                let authData = JSON.parse(authString);
                var token= authData ? authData.user.token : "";
                this.httpRequest.setRequestHeader('Access-Token',token);
                this.httpRequest.setRequestHeader('Content-Type','application/json');
                this.httpRequest.responseType = "text";// 设置请求头为text格式
                this.httpRequest.send(JSON.stringify(_data));//发送请求
            },
            stopMessage(){
                if(this.httpRequest){
                    this.httpRequest.abort();
                }
            },
            selectFile(){
                this.$refs['chatFile'].click();
            },
            deleteImage(){
                this.selectedImage = null;
            },
            handleFileChange(event) {
                // var that=this;
                var file = event.target.files[0];

                if (!file) {
                    console.log('No file selected');
                    return;
                }
                this.uploadFile(file);
            },
            clipPaste(){
                //剪贴板
                var file;
                const clipboardData = event.clipboardData || window.clipboardData;
                const items = clipboardData.items;
                if (items[0].type.indexOf('image') !== -1) {
                    file = items[0].getAsFile();
                }
                if (!file) {
                    console.log('No file selected');
                    return;
                }
                this.uploadFile(file);
            },
            uploadFile(file){
                // 处理文件
                // this.selectedImage = URL.createObjectURL(file);
                var that=this;
                that.chatListLoading=true;
                const formData = new FormData();
                formData.append("file", file);
                formData.append("sessionid", this.currentSession.ID);
                fetch(config.apiHost + '/api/gpt/uploadfile/', {
                    method: 'POST',
                    headers: {
                        'Access-Token':''
                    },
                    mode: 'cors',
                    credentials: 'include',
                    body: formData,
                })
                .then(response => response.json())
                .then(json=>{
                    debugger
                    that.chatListLoading=false;
                    if(json.Success){
                        // 创建一个新的 Audio 对象
                        // var audio = new Audio();
                        // 将 base64 字符串设置为音频源
                        that.selectedImage = json.Data;
                        // 播放音频
                        // audio.play();
                        // 可选：处理播放错误
                        // audio.onerror = function() {
                        //     console.error("音频播放失败");
                        // };
                    }
                })
                .catch(e => {
                    this.chatListLoading=false;
                    console.log(e);
                });
            },
            tts(item){
                this.chatListLoading=true;
                this.loadingText='正在处理...';
                fetch(config.apiHost + '/api/gpt/tts/', {
                    method: 'POST',
                    headers: {
                        'Access-Token':''
                    },
                    mode: 'cors',
                    credentials: 'include',
                    body: JSON.stringify({text:item.message}),
                })
                .then(response => response.json())
                .then(json=>{
                    debugger
                    this.chatListLoading=false;
                    if(json.Success){
                        var base64String=json.Data;
                        // 创建一个新的 Audio 对象
                        // var audio = new Audio();
                        // 将 base64 字符串设置为音频源
                        item.audio = "data:audio/mp3;base64,"+base64String;
                        // 播放音频
                        // audio.play();
                        // 可选：处理播放错误
                        // audio.onerror = function() {
                        //     console.error("音频播放失败");
                        // };
                    }
                })
                .catch(e => {
                    this.chatListLoading=false;
                    console.log(e);
                });
            },
            copyMessage(text){
                  //var url='@serviceConfig.ServiceHost'+`s/f/${file.ID}${file.ExtendName}`;
                var isRTL = document.documentElement.getAttribute('dir') === 'rtl';
                var element = document.createElement('textarea');
                // 防止在ios中产生缩放效果
                element.style.fontSize = '12pt';
                // 重置盒模型
                element.style.border = '0';
                element.style.padding = '0';
                element.style.margin = '0';
                // 将元素移到屏幕外
                element.style.position = 'absolute';
                element.style[isRTL ? 'right' : 'left'] = '-9999px';
                // 移动元素到页面底部
                let yPosition = window.pageYOffset || document.documentElement.scrollTop;
                element.style.top = `${yPosition}px`;
                //设置元素只读
                element.setAttribute('readonly', '');
                element.value = text;
                document.body.appendChild(element);
                element.select();
                element.setSelectionRange(0, element.value.length);
                document.execCommand('copy');
                element.remove();
                this.showMessage('复制成功');
            }
        },
        created: function () {

        },
        mounted: function () { //dom已渲染
            var that = this;
            that.isMobile = that.getIsMobile();
            window.onresize = function () {
                that.isMobile = that.getIsMobile();
            }
            if(!this.isMobile){
                //计算高度
                var bottom =document.querySelector('.chatBox-bottom');
                if(bottom){
                    var resizeObserver = new ResizeObserver(entries => {
                        for (let entry of entries) {
                            // 获取新的高度
                            let newHeight = entry.contentRect.height;
                            console.log('元素高度发生变化，新的高度是：' + newHeight);
                            // 在这里执行你的逻辑
                            //计算并重新设置高度
                            var main =document.querySelector('.main');
                            var middle =document.querySelector('.chatBox-middle');
                            debugger
                            middle.style.height=(main.offsetHeight-newHeight-40)+'px';
                        }
                    });
                    // 开始观察目标元素
                    resizeObserver.observe(bottom);
                }
            }
            if (!this.isLogin) {
                this.$router.push({
                    name: 'login'
                })
            } else {
                // this.$store.dispatch('user/getUserInfo');
                this.loadUserInfo();
            }
            var sessionId=this.getCookie('session_id');
            if(sessionId){
                this.session_id=sessionId;
                this.currentSession={ID:sessionId,Name:'默认聊天'};
                this.setCookie('session_id',sessionId,7);//设置1天
                this.sessionList.push(this.currentSession);
            }
            else{
                let _session_id=this.guid2();
                this.currentSession={ID:_session_id,Name:'默认聊天'};
                this.setCookie('session_id',_session_id,7);//设置1天
                this.sessionList.push(this.currentSession);
            }
            that.loadSessionList((s => {
                if (s.length == 0) {
                    let _session_id = this.guid2();
                    that.currentSession = {
                        ID: _session_id,
                        Name: '默认聊天'
                    };
                    that.sessionList.push(that.currentSession);
                    // this.setCookie('session_id',_session_id,7);//设置1天
                    // this.sessionList.push(this.currentSession);
                    GPTService.SaveSession(that.currentSession).then(s => {
                        // if(s.Success){
                        // }
                        this.sessionLoading = false;
                    }).catch(() => {
                        this.sessionLoading = false;
                    });
                } else {
                    that.currentSession = s[0];
                    that.loadChatList(this.currentSession.ID);
                }
                if (that.currentSession) {
                    // that.connectChatServer(that.currentSession.ID);
                }
            }));
            // let testText='|  表头  | 表头  |\r\n'+
            //     '|  ----  | ----  |\r\n'+
            //     '| 单元格  | 单元格 | | 单元格 |\r\n'+
            //     '| 单元格  | 单元格 |';

            // testText='| ID | 姓名 |\n|----|------|\n|1 | 张三 |\n|2 | 李四 |\n|3 | 王五 |\n|4 | 赵六 |\n|5 | 钱七 |';
            //     let tempArrar=testText.split('');
            // setTimeout(()=>{
            //        this.testIdx=testText.length;
            //         setInterval(()=>{
            //             if(this.testIdx>=tempArrar.length){
            //                 that.currentChatMessage=null;
            //                 this.testIdx=0;
            //             }
            //             if (that.currentChatMessage) {
            //                 this.testIdx++;
            //                 that.currentChatMessage.message += tempArrar[this.testIdx];
            //                 that.scrollToBottom();
            //             }
            //             else {
            //                 let customerData = {
            //                     id: 4,
            //                     name: 'GPT',
            //                     isGPT: true,
            //                     time: new Date(),
            //                     message:'',
            //                 }
            //                 that.appendMessage(customerData);
            //                 that.currentChatMessage = customerData;
            //             }
            //         },100)
            // },2000);
        },
    }
</script>
<style lang="scss" scoped>
    @import url("https://fastly.jsdelivr.net/npm/vant@4/lib/index.css");

    //  @import url("https://unpkg.com/element-plus/dist/index.css");
    html,
    body {
        margin: 0;
        padding: 20px;
        width: 100%;
        height: 100%;
    }

    #chat {
        // display: flex;
        // height: 100vh;
        // overflow: hidden;
        position: absolute;
        height: 100%;
        width: 100%;
        overflow: hidden;
        // overflow-y: auto;
    }

    .chatBox {
        width: 100%;
        height: 100%;
        background-color: #fff;
        display: flex;
        flex-direction: column;

        // box-sizing: content-box;
        // border-radius: 0.625rem;
        .chatBox-top {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            padding: 0.56rem 1rem;
            box-sizing: border-box;
            /* height: 3rem; */
            background-color: #1989fa;
        }

        .chatBox-middle {
            background-color: #fff;
            overflow: auto;
            // flex:1;
            height: calc(100% - 12rem);

            .chatInfo {
                width: 100%;
                height: 100%;
                padding: 1rem;
                overflow: auto;
                box-sizing: border-box;
            }
        }

        .chatBox-bottom {
            // height: 10rem;
            padding-top: 1rem;
        }
    }

    .chatBox-top-imgBox {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .chatBox-top-text {
        padding-left: 1.25rem;
        font-size: 1rem;
        color: #fff;
        flex: 1;
        align-items: center;
        display: flex;
    }

    .chatBox-textarea {
        width: 100%;
        border: solid 1px #eee;
    }

    .gpt-message-box {
        width: 100%;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
    }

    .user-box-img {
        display: flex;
    }

    .chat-message {
        margin: 0 1.25rem;
        position: relative;
    }

    .chat-message-name {
        font-size: 0.875rem;
        color: #888;
        display: flex;
        flex-direction: row;
    }

    .nowDate {
        margin: 0 0.625rem;
    }

    #chat .chat-message-text {
        margin-top: 0.85125rem;
        max-width: 40rem;
        padding: 0rem 1rem;
        background-color: #f8f8f8;
        border-radius: 0.5rem;
        float: left;
        table-layout: fixed;
        word-break: break-all;
        overflow: hidden;
    }

    #chat .chat-message-text span {
        font-size: 0.9375rem;
        line-height: 1.5;
    }

    #chat .chat-message-text span-code {
        font-size: 0.9375rem;
        line-height: 1;
    }

    #chat .my-message-box {
        flex-direction: row-reverse;
    }

    #chat .chat-message-name1 {
        flex-direction: row-reverse;
    }

    #chat .chat-message-text1 {
        float: right;
    }

    .pc {
        height: 100%;
        background-color: #fff;

        .header {
            padding: 0 !important;
            height: auto !important;
            -webkit-box-shadow: -7px 3px 10px 0 rgba(255, 255, 255, .06);
            box-shadow: -7px 3px 10px 0 rgba(255, 255, 255, .06);

            .chatBox-top {
                width: 100%;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                padding: 0.56rem 1rem;
                box-sizing: border-box;
                background-color: #1989fa;
            }

        }

        .side {
            padding: 5px;
            width: 200px;
            display: flex;
            flex-direction: column;
            border-right: 1px solid #f4f4f4;

            .tools {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
            }

            .footer {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 2rem;
                padding: 0.2rem;
                border-top: #f4f4f4 1px solid;
            }

            .session-list {
                // display: flex;
                align-items: center;
                padding: 10px;
                flex-direction: column;
                align-items: center;
                overflow-x: hidden;
                overflow-y: auto;
                /* overflow: auto; */
                // height: calc(100% - 260px);
                .session-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: #dadfe5 1px solid;
                    width: 100%;
                    border-radius: 3px;
                    padding: 10px 0;
                    margin-bottom: 10px;
                    cursor: pointer;

                    .session-name {
                        color: #333;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }

                .session-active {
                    border: #1989fa 1px solid;

                    .session-name {
                        color: #1989fa;
                        font-weight: bold;
                    }
                }
            }
        }

        .main {
            padding: 10px;
            height: 100%;
            box-sizing: border-box;

            .chatBox-middle {
                background-color: #fff;
                overflow: auto;
                // flex:1;
                height: calc(100% - 8.2rem);
                .chatInfo {
                    width: 100%;
                    height: 100%;
                    padding: 1rem;
                    overflow: auto;
                    box-sizing: border-box;
                }
            }

            .chatBox-bottom {
                // height: 10rem;
                position: relative;
                padding-top: 1rem;
            }
            .chatBox-message-input{
                min-height: 33px;
                width: 100%;
                margin: 0 10px;
                padding: 15px 15px 15px 50px;
                border-radius: 26px;
                font-size: 14px;
                border: solid 1px #eee;
            }
            .chatBox-message-input-img{
                min-height: 33px;
                width: 200px;
                margin: 10px 0px;
                border: solid 1px #f1f1f1;
                border-radius: 6px;
            }
            .chatBox-message-input-img-del{
                position: absolute;
                top: 30px;
                background: rgba(255, 0, 0, 0.7);
                color: white;
                border-radius: 50%;
                //padding: 5px;
                cursor: pointer;
                font-size: 16px;
                line-height: 16px;
                text-align: center;
                width: 18px;
                height: 18px;
            }
            .chatBox-message-input-text{
                min-height: 33px;
                width: 100%;
                font-size: 14px;
                border: none;
            }
        }
    }
</style>
